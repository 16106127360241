
import { vxm } from '@/store/index';
import { Vue, Component } from 'vue-property-decorator';
import DateHelper from "@/helpers/dateHelper";

@Component({})

export default class NavigationDrawer extends Vue {
  private mini = true;

  private get menuItems() { return vxm.GeneralStore.menuItems; }

}
