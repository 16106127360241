
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as JobAPI from '@/api/jobAPI';
import { Pie } from 'vue-chartjs/legacy';
import PieGraphDataItem from '../../shared/pieGraphDataItem';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

@Component({
  components: {
    Pie
  }
})
export default class JobsByStatusPieGraph extends Vue {
  @Prop(String) public locationID: string | null;
  @Prop(String) public clientID: string | null;

  //For some reason the height and width values are being ignored even though they are set
  //Removing these values all together stop the graph from rendering
  //Instead the graph is using styles to set its width and height
  private width = 100;
  private height = 100;
  private numberOfMonths = 24;
  private items: PieGraphDataItem[] = [];
  private itemDirtyFlag = false;

  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  }

  @Watch('locationID', { immediate: true, deep: true })
  onItemChanged() {
    this.load();
  }

  @Watch('clientID', { immediate: true, deep: true })
  onClientIDLoaded() {
    if (this.clientID != "")
      this.load();
  }

  private async load() {
    this.loadError = "";
    this.loading = true;

    try {
      //Included a "numberOfMonths" variable for testing purposes
      this.items = await JobAPI.getJobsByStatus(this.locationID, this.numberOfMonths);

      if (this.items.length > 0)
      {
        const labels: string[] = [];
        const data: number[] = [];
        const backgroundColours: string[] = [];

        for (let i = 0; i < this.items.length; i++) {
          labels.push(this.items[i].Label);
          data.push(this.items[i].Amount);
          backgroundColours.push(this.items[i].ColourHex);
        }

        this.chartData.labels = labels;
        this.chartData.datasets = [
          {
            backgroundColor: backgroundColours,
            data: data,
          }
        ]
      }
      else {
        this.chartData.labels = ['no data available'];
        this.chartData.datasets =  [
          {
            backgroundColor: ['#aba4a4'],
            data: [0],
          }
        ]
      }

    } catch (ex: Error) {
       this.$emit("onLoad", ex);
    } finally {
      this.loading = false;
    }
  }
}
