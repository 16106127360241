import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from "vuex-persist";
import { extractVuexModule, createProxy } from "vuex-class-component";

import {AuthStore} from '@/store/authStore';
import { GeneralStore } from '@/store/generalStore'

Vue.use(Vuex)
/**
 * Any modules listed here will have their store state persisted.
 *
 * Anything persisted will keep the store the same across page reloads.
 *
 * Anything not persisted will reset to its default values when the browser
 * reloads the page. Using the Vue internal router for page navigation does
 * *not* reload the page in the browser, and therefore the store will not
 * be reset when we do this. Things that will reset the state for anything
 * not persisted:
 * - window.location.href
 * - user entering URL in browser and navigating manually
 * - browser refresh button
 *
 * See https://github.com/championswimmer/vuex-persist for more options
**/
const vuexLocal = new VuexPersistence({
    storage: window.localStorage, // where to persist the data (localStorage, sessionStorage, etc)
    modules: ['auth'] // which modules will have their store state persisted
});

export const store = new Vuex.Store({
  plugins: [
      vuexLocal.plugin
  ],
  modules: {
    ...extractVuexModule(GeneralStore),
    ...extractVuexModule(AuthStore)
  }
})

export const vxm = {
  GeneralStore: createProxy(store, GeneralStore),
  AuthStore: createProxy(store, AuthStore)
};
