
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as JobAPI from '@/api/jobAPI';
import Job from '../../../../shared/models/job';

@Component({})
export default class UpcomingJobsList extends Vue {
  @Prop(String) public locationID: string | null;
  @Prop(String) public clientID: string | null;

  private get headers() {
    return [
      {
        text: 'Job Ref.',
        value: 'JobReference',
        sortable: false,
      },
      {
        text: 'Location',
        value: 'Location',
        sortable: false,
      },
      {
        text: 'Job Status',
        value: 'JobStatus',
        sortable: false,
      },
      {
        text: 'Start Date',
        value: 'StartDate',
        sortable: false,
      },
    ];
  }

  private loading = false;
  private upcomingJobs: Job[] = [];

  @Watch('locationID', { immediate: true, deep: true })
  onItemChanged() {
    this.load();
  }

  @Watch('clientID', { immediate: true, deep: true })
  onClientIDLoaded() {
    if (this.clientID != "")
      this.load();
  }
  
  private async load() {
    this.loading = true;

    try {
      this.upcomingJobs = await JobAPI.getTenUpcomingJobs(this.locationID);
    } catch (ex: Error) {
       this.$emit("onLoad", ex);
    } finally {
      this.loading = false;
    }
  }
}
