
import { Component, Vue, Watch } from 'vue-property-decorator';
import NavigationDrawer from "./components/global/navigationDrawer.vue"
import { vxm } from '@/store/index';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { Console } from 'console';

@Component({
  components: {
    NavigationDrawer,
  }
})

export default class ClientPortalApp extends Vue {
  private versionNumber = process.env.VUE_APP_VERSION;
  private environment = process.env.VUE_APP_ENVIRONMENT;

  private get currentRouteName() {
    return this.$route.meta.title != null ? this.$route.meta.title : this.$route.name;
  }
  get selectedClient() { return  vxm.AuthStore.targetClient; }
  get userClients() {
    if(vxm.AuthStore.user === null || vxm.AuthStore.user === undefined) {
      return []
    }
    return vxm.AuthStore.user!.Clients
  }

  changeSelectedClient(clientID: string){
    vxm.AuthStore.setTargetClient(clientID)
  }
}
