

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { Component, Prop, Vue } from 'vue-property-decorator';

import auth from '@/services/auth';

@Component({})
export default class LoginError extends Vue {
    private retry(): void {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;

      auth.processLogin().then(
        () => {
          // Redirect to the default page 
          that.$router.push({
            path: "/"
          });
        },
        () => {
          that.$router.push({
            path: "/loginerror"
          });
        }
      );
    }

    private logOut(): void {
      this.$router.push({
        path: "/logout"
      });
    }
}
