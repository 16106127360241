
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { Component, Prop, Vue } from 'vue-property-decorator';

import auth from '@/services/auth';

@Component({
  components: {},
})

export default class LoggedOut extends Vue {
  private show = true;
  
  private signIn(): void {
    auth.signIn();
  }
}
