
import { Vue, Component, Watch } from 'vue-property-decorator';
import UpcomingJobList from '@/components/dashboard/UpcomingJobsList.vue';
import JobsByStatusPieGraph from '@/components/dashboard/JobsByStatusPieGraph.vue';
import RecentJobReportsList from '@/components/dashboard/RecentJobReportsList.vue';
import * as LocationAPI from '@/api/locationAPI';
import PaginatedResults from '../../../../shared/models/paginatedResults';
import * as UserAPI from '@/api/userAPI';
import User from '../../../../shared/models/user';
import { vxm } from '@/store';

@Component({
  components: {
    UpcomingJobList,
    JobsByStatusPieGraph,
    RecentJobReportsList
  },
})
export default class DashboardView extends Vue {
  private error = "";
  private selectedLocationID: string | null = null;
  private selectedLocationName: string | null = null;
  private loggedInUser: User | null = null;

  private items: PaginatedResults = {
    totalResults: 0,
    results: []
  };

  
  get selectedClientID() { return  vxm.AuthStore.targetClient?.ClientID; }
  private async mounted() {
    try {
      this.loggedInUser = await UserAPI.getLoggedInUser();
      if(this.selectedClientID === undefined){
        throw new Error("No client selected");
      }
      this.items = await LocationAPI.getLocations(1, -1,'LocationName', false, null, null, null, null);
    }
    catch (ex: Error) {
      this.error = ex.message;
    }
  }

  private errorHandler(ex: Error) {
    this.error = ex.message;
  }

  @Watch('selectedLocationID', { immediate: true, deep: true })
  onItemChanged() {
    this.selectedLocationName == null;
    if (this.selectedLocationID == null) { return; }

    for (let i = 0; i < this.items.results.length; i++) {
      if (this.items.results[i].LocationID == this.selectedLocationID) {
        this.selectedLocationName = this.items.results[i].LocationName;
        return;
      }
    }
  }

  @Watch('selectedClientID', { immediate: true, deep: true })
  async onClientIDChanged() {
    this.items = await LocationAPI.getLocations(1, -1,'LocationName', false, this.selectedClientID, null, null, null, null);
  }
}
