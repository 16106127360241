import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import {store} from './store'
import DateHelper from "@/helpers/dateHelper";

Vue.config.productionTip = false
Vue.prototype.$dateHelper = DateHelper;

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
