import * as BaseAPI from '@/api/baseAPI';
import PaginatedResults from '../../../shared/models/paginatedResults';
import Report from '../../../shared/models/report';
import { vxm } from '@/store';

export async function getReports(page:number, itemsPerPage:number, sortBy:string | null, sortDesc:boolean | null, jobReference:string, stateID:string | null, location:string | null, reportTypeID:number | null, publishDateFrom:string | null, publishDateTo:string | null): Promise<PaginatedResults> {
  if(vxm.AuthStore.targetClient?.ClientID === undefined){
    throw new Error('No Client selected')
  }

  const result = await BaseAPI.simpleGet(`/reports?${[
    `page=${page}`,
    `itemsPerPage=${itemsPerPage}`,
    `sortBy=${sortBy}`,
    `sortDesc=${sortDesc}`,
    `clientID=${vxm.AuthStore.targetClient?.ClientID}`,
    `jobReference=${jobReference}`,
    `stateID=${stateID}`,
    `location=${location}`,
    `reportTypeID=${reportTypeID}`,
    `publishDateFrom=${publishDateFrom}`,
    `publishDateTo=${publishDateTo}`,
  ].join("&")}`);
  return result.data;
}

export async function getTenRecentReports(clientID: string, locationID: string | null): Promise<PaginatedResults> {
  const result = await BaseAPI.simpleGet(`/reports/recent?clientID=${clientID}&locationID=${locationID}`);
  return result.data;
}
