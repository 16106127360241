import * as authAPI from "@/api/authApi";
import { vxm } from '@/store';
import { Amplify, Auth } from "aws-amplify";

//configure auth on startup
Amplify.configure({
  Auth: {
    // identityPoolId: 'ap-southeast-2:daa59ab4-3107-4e96-b4ea-2982cd868a41',
    region: process.env.VUE_APP_AWS_REGION,
    userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
    oauth: {
      domain: process.env.VUE_APP_COGNITO_APP_DOMAIN + ".auth." + process.env.VUE_APP_AWS_REGION + ".amazoncognito.com",
      scope: ['profile',  'openid', 'email', 'aws.cognito.signin.user.admin'], 
      redirectSignIn: process.env.VUE_APP_COGNITO_REDIRECT_URL_SIGNIN,
      redirectSignOut: process.env.VUE_APP_COGNITO_REDIRECT_URL_SIGNOUT,
      responseType: "code" // refresh token only provided with "code", not provided if we choose "token" (apparently: I read that on the internet somewhere)
    }
  }
});

export default {
  async signIn(): Promise<void> {
    // delete user data in case still exists
    this.forgetUser();

    // this will redirect the user to the Cognito hosted log in page
    // once we have successfully logged in there, we will eventually come back to the redirect URL, which is /loggingin
    Auth.federatedSignIn()
  },
  logOut(): void {
    this.forgetUser();

    // this will redirect user to the Cognito log out page
    Auth.signOut();
  },
  forgetUser(): void {
    vxm.AuthStore.forgetUser()
  },
  async processLogin(): Promise<void> {
    try {
      const user = await authAPI.getUser();
      vxm.AuthStore.userLoggedIn(user);
    }
    catch (ex) {
      // eslint-disable-next-line no-console
      console.log("error getting user as part of login process", ex);

      // Something went wrong
      this.forgetUser();
    }
  },
}
