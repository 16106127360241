
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ReportList from '@/components/reports/ReportList.vue';
import State from '../../../shared/models/state'
import ReportType from '../../../shared/models/reportType'
import DateRangePicker from '@/components/global/DateRangePicker.vue';
import * as stateAPI from '@/api/stateAPI';
import * as reportTypeAPI from '@/api/reportTypeAPI';
import * as locationAPI from '@/api/locationAPI';
import { vxm } from '@/store';

@Component({
  components: {
    ReportList,
    DateRangePicker
  },
})
export default class ReportsView extends Vue{
  @Prop(String) public jobReference: string | null;
  @Prop(String) public locationName: string | null;
  @Prop(String) public locationID: string | null;

  private error = "";
  private searchedJobReference: string | null = null;
  private selectedStateID: number | null = null;
  private searchedLocation: string | null = null;
  private seletedReportTypeID: number | null = null;
  private selectedPublishDateTo: string | null = null;
  private selectedPublishDateFrom: string | null = null;

  private states: State[] = [];
  private reportTypes: ReportType[] = [];

  get selectedClientID() { return  vxm.AuthStore.targetClient?.ClientID; }
  
  @Watch('selectedClientID', { immediate: true, deep: true })
  async onClientIDChanged() {
    this.onSearchChanged();
  }

  private async mounted() {
    try {
      if (this.jobReference != null && this.jobReference != "null")
        this.searchedJobReference = this.jobReference;
      
      if (this.locationID != null && this.locationID != "null") {
        const location: Location = await locationAPI.getLocation(this.locationID);

        this.selectedStateID = location.StateID;
        this.searchedLocation = location.LocationName;
      }
      
      this.states = await stateAPI.getStates();
      this.reportTypes = await reportTypeAPI.getReportTypes();
    } 
    catch (ex: Error) {
      this.error = ex.message;
    }
  }

  private async onSearchChanged() {
    this.$refs.reportListChild.load();
  }

  private errorHandler(errorMessasge) {
    this.error = errorMessasge;
  }

  private publishDateInputHandler(dates: string[] | null) {
    this.selectedPublishDateFrom = dates != null ? dates[0] : null;
    this.selectedPublishDateTo = dates != null && dates.length > 1 ? dates[1] : null;
  
    this.onSearchChanged();
  }

  private closeAlert() {
    this.error = "";
  }

  private clearFilters() {
    this.searchedJobReference = null;
    this.selectedStateID = null;
    this.searchedLocation = null;
    this.seletedReportTypeID = null;
    this.selectedPublishDateTo = null;
    this.selectedPublishDateFrom = null;

    this.$refs.jobPublishedDatePicker.clear_NonEmitting();
    this.$refs.reportListChild.load();
  }
}
