
import { Vue, Component, Prop } from 'vue-property-decorator';
import State from '../../../shared/models/state';
import JobStatus from '../../..shared/models/jobStatus';
import JobList from '@/components/jobs/JobList.vue';
import DateRangePicker from '@/components/global/DateRangePicker.vue';
import * as locationAPI from '@/api/locationAPI';
import * as stateAPI from '@/api/stateAPI';
import * as jobStatusAPI from '@/api/jobStatusAPI';

@Component({
  components: {
    JobList,
    DateRangePicker
  },
})
export default class JobsView extends Vue{
  @Prop(String) public locationID: string | null;
  @Prop(String) public upcomingStatus: string | null;

  private error = "";
  private searchedJobReference: string | null = null;
  private selectedStateID: number | null = null;
  private searchedLocation: string |null = null;
  private selectedStatusIDs: number[] | null = null;
  private selectedStartDateFrom: string | null = null;
  private selectedStartDateTo: string | null = null;
  private selectedFinalisedDateFrom: string | null = null;
  private selectedFinalisedDateTo: string | null = null;

  private states: State[] = [];
  private statuses: JobStatus[] = [];

  private async mounted() {
    try {
      if (this.locationID != null && this.locationID != "null") {
        const location: Location = await locationAPI.getLocation(this.locationID);

        this.selectedStateID = location.StateID;
        this.searchedLocation = location.LocationName;
        this.$refs.jobListChild.load();
      }

      this.states = await stateAPI.getStates();
      this.statuses = await jobStatusAPI.getJobStatuses();

     if (this.upcomingStatus != null && this.upcomingStatus == "upcoming") {
        const upcomingStatuses: number[] = this.statuses.filter(x => x.IsUpcoming).map(x => x.JobStatusID);
        this.selectedStatusIDs = upcomingStatuses;
      }
    } 
    catch (ex: Error) {
      this.error = ex.message;
    }
  }

  private async onSearchChanged() {
    this.$refs.jobListChild.load();
  }

  private startDateInputHandler(dates: string[] | null) {
    this.selectedStartDateFrom = dates != null ? dates[0] : null;
    this.selectedStartDateTo = dates != null && dates.length > 1 ? dates[1] : null;

    this.onSearchChanged();
  }

  private finalisedDateInputHandler(dates: string[] | null) {
    this.selectedFinalisedDateFrom = dates != null ? dates[0] : null;
    this.selectedFinalisedDateTo = dates != null && dates.length > 1 ? dates[1] : null;
    
    this.onSearchChanged();
  }

  private clearFilters() {
    this.searchedJobReference = null;
    this.selectedStateID = null;
    this.searchedLocation = null;
    this.selectedStatusIDs = null;
    this.selectedStartDateFrom = null;
    this.selectedStartDateTo = null;
    this.selectedFinalisedDateFrom = null;
    this.selectedFinalisedDateTo = null;

    this.$refs.jobStartDatePicker.clear_NonEmitting();
    this.$refs.jobFinalisedDatePicker.clear_NonEmitting();
    this.$refs.jobListChild.load();
  }
  
  private errorHandler(errorMessasge) {
    this.error = errorMessasge;
  }

  private closeAlert() {
    this.error = "";
  }
}
