import * as BaseAPI from '@/api/baseAPI';
import PaginatedResults from '../../../shared/models/paginatedResults';
import Job from '../../../shared/models/job';
import PieGraphDataItem from '../../../shared/pieGraphDataItem';
import { vxm } from '@/store';

export async function getJobs(page:number
                              ,itemsPerPage: number
                              ,sortBy: string | null
                              ,sortDesc: boolean | null
                              ,jobReference: string | null
                              ,stateID: string | null
                              ,location: string | null
                              ,statusIDs: number[] | null
                              ,startDateFrom: string | null
                              ,startDateTo: string | null
                              ,finalisedDateFrom: string | null
                              ,finalisedDateTo: string | null): Promise<PaginatedResults> {

  if(vxm.AuthStore.targetClient?.ClientID === undefined){
    throw new Error('No Client selected')
  }
  const result = await BaseAPI.simpleGet(`/jobs?${[
    `page=${page}`,
    `itemsPerPage=${itemsPerPage}`,
    `sortBy=${sortBy}`,
    `sortDesc=${sortDesc}`,
    `clientID=${vxm.AuthStore.targetClient!.ClientID}`,
    `jobReference=${jobReference}`,
    `stateID=${stateID}`,
    `location=${location}`,
    `statusIDs=${statusIDs}`,
    `startDateFrom=${startDateFrom}`,
    `startDateTo=${startDateTo}`,
    `finalisedDateFrom=${finalisedDateFrom}`,
    `finalisedDateTo=${finalisedDateTo}`,
  ].join("&")}`);
  return result.data;
}

export async function getTenUpcomingJobs(locationID: string | null): Promise<Job[]> {
  
  if(vxm.AuthStore.targetClient?.ClientID === undefined){
    throw new Error('No Client selected')
  }

  const result = await BaseAPI.simpleGet(`/jobs/upcoming?${[`clientID=${vxm.AuthStore.targetClient?.ClientID}`,`locationID=${locationID}`].join("&")}`);
  return result.data;
}

export async function getJobsByStatus(locationID: string | null, numberOfMonths: number): Promise<PieGraphDataItem[]> {
  
  if(vxm.AuthStore.targetClient?.ClientID === undefined){
    throw new Error('No Client selected')
  }

  const result = await BaseAPI.simpleGet(`/jobs/status?${[`clientID=${vxm.AuthStore.targetClient?.ClientID}`,`locationID=${locationID}`,`numberOfMonths=${numberOfMonths}`].join("&")}`);
  return result.data;
}