
import { createModule, mutation } from "vuex-class-component";
import { RouteConfig } from 'vue-router';

const VuexModule = createModule({
  namespaced: "general",
  strict: false
});

export class GeneralStore extends VuexModule {
  loadingRoute = false;
  menuItems: RouteConfig[] = [];

  @mutation public setLoadingRoute(value: boolean) {
    this.loadingRoute = value;
  }
  @mutation public setMenuItems(payload: { menuItems: RouteConfig[] }) {
    const allowedItems: RouteConfig[] = payload.menuItems;

    this.menuItems = allowedItems
  }
}
