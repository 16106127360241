
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as ReportAPI from '@/api/reportAPI';
import PaginatedResults from '../../../../shared/models/paginatedResults';
import Report from '../../../../shared/models/report';
import * as UserAPI from '@/api/userAPI';
import User from '../../../../shared/models/user';
import reportsHelper from '@/helpers/reportsHelper';

@Component({})
export default class ReportList extends Vue {
  @Prop(String) public jobReference: string | null;
  @Prop(Number) public stateID: number | null;
  @Prop(String) public location: string | null;
  @Prop(Number) public reportTypeID: number | null;
  @Prop(String) public publishDateFrom: string | null;
  @Prop(String) public publishDateTo: string | null;

  @Watch('options', { immediate: true, deep: true })
  onOptionsChanged(value: any, oldValue: any) {
    if(oldValue != undefined) {
      this.load();
    }
  }

  private get headers() {
    return [
      {
        text: 'Job Ref.',
        value: 'JobReference',
        sortable: true,
      },
      {
        text: 'State',
        value: 'State',
        sortable: true,
      },
      {
        text: 'Location',
        value: 'Location',
        sortable: true,
      },
      {
        text: 'Report Type',
        value: 'ReportType',
        sortable: true,
      },
      {
        text: 'Publish Date',
        value: 'PublishDate',
        sortable: true,
      },
      {
        text: 'Link',
        value: 'File',
        sortable: false,
      },
    ];
  }

  private loading = false;
  private loadError = "";
  private downloadingReport = false;
  private errorDownloadReport = false;
  private reportError: string | null = null;
  private options = { sortBy:[], sortDesc:[], page: 1, itemsPerPage: 20 }; 
  private loggedInUser: User | null = null;

  private items: PaginatedResults = {
    totalResults: 0,
    results: []
  };

  private async load() {
    this.loading = true;

    await this.$nextTick();
    //Race condition with state ID change between parent an child components

    const { sortBy, sortDesc, page, itemsPerPage } = this.options;
    this.loggedInUser = await UserAPI.getLoggedInUser();

    this.items = {
      totalResults: 0,
      results: []
    };

    try {
      this.items = await ReportAPI.getReports(page
        , itemsPerPage
        , sortBy.length === 1 ? sortBy[0] : null
        , sortDesc.length === 1 ? sortDesc[0] : null
        , this.jobReference!
        , this.stateID
        , this.location
        , this.reportTypeID
        , this.publishDateFrom
        , this.publishDateTo);
    } catch (ex) {
      this.$emit("onLoad", ex);
    } finally {
      this.loading = false;
    }
  }

  private async downloadReport(report: Report): Promise<void> {
    this.downloadingReport = true;

    try {
      await reportsHelper.download(report);
    }
    catch(e) {
      this.downloadingReport = false;
      
      this.errorDownloadReport = true;
      this.reportError = (e as Error).message

      // eslint-disable-next-line no-console
      console.log('in component catch', e)
    }
    finally {
      this.downloadingReport = false;
    }
  }
}
