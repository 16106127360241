
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import DateHelper from "@/helpers/dateHelper";

@Component({})
export default class DateRangePicker extends Vue {
  private menu = false;
  private selectedDates: Date[] = [];
  private formattedDates = "";

  @Prop(String) title: string; 

  @Watch('selectedDates', { immediate: true, deep: true })
  onOptionsChanged() {
    if (this.selectedDates == null) 
    {
        this.formattedDates = "";
        return;
    } 
    else if (this.formattedDates.indexOf("-") > 0)
      return;

    const startDate = this.selectedDates[0];
    const endDate = this.selectedDates[1];

    if (endDate != null)
      this.formattedDates += ` - ${DateHelper.GetDateAsDMYString(endDate)}`;
    else if (startDate != null)
      this.formattedDates += DateHelper.GetDateAsDMYString(startDate);
  }

  private confirm() {
    const endDateBeforeStartDate: boolean = this.selectedDates[1] < this.selectedDates[0];
    if (endDateBeforeStartDate)
      this.selectedDates = [this.selectedDates[1], this.selectedDates[0]];

    this.$refs.menu.save(this.selectedDates);
    this.$emit("onInput", this.selectedDates);
  }

  private clear() {
    this.selectedDates = null;
    this.formattedDates = "";
    this.$refs.menu.save(this.selectedDates);
    this.$emit("onInput", this.selectedDates);
  }

  private clear_NonEmitting() {
    this.selectedDates = null;
    this.formattedDates = "";
    this.$refs.menu.save(this.selectedDates);
  }
}
