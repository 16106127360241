import Report from "../../../shared/models/report";
import axios, { AxiosResponse } from 'axios';
import { AxiosRequestConfig } from "axios";

export default class ReportsHelper {
    
    public static async download(report: Report): Promise<any> {
        const mime_Type = report.MIME_Type;
        const base64EncodedID = btoa(report.ReportID);
    
        //The PHP serveless code that returns reports will return responses in the 200 range even if the report was not located
        //Because of that, check for the "error" text placed inside of response body and stop the Blob fetch if it is
        const bodyFormData = new FormData()
        bodyFormData.append('secret', `${process.env.VUE_APP_PHP_API_SECRET}`)
        const textConfig: AxiosRequestConfig = {
            url: `${process.env.VUE_APP_PHP_API_ENDPOINT}/download_report/${base64EncodedID}`,
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            data: bodyFormData,
            responseType: "blob"
        };
        try {
            const res = await axios(textConfig)

            // check to ensure the file was found before constructing the blob
            const stringResponse = JSON.stringify(res.data);
            if (stringResponse.indexOf("No such file or directory") > 0)
                throw new Error("Unable to locate file");
            
            // file resolved, construct the blob
            const blob = new Blob([res.data],  {type: mime_Type} );
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', report.File);
            document.body.appendChild(link);

            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        }
        catch(e) {
            // eslint-disable-next-line no-console
            console.log('ReportsHelper catch', e)
            throw e
        }
    }
}