
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as locationAPI from '@/api/locationAPI';
import PaginatedResults from '../../../../shared/models/paginatedResults';
import * as UserAPI from '@/api/userAPI';
import User from '../../../../shared/models/user';

@Component({})
export default class LocationList extends Vue {
  @Prop(Number) public stateID: number | null;
  @Prop(String) public location: string | null;
  @Prop(String) public contact: string | null;
  @Prop(String) public email: string | null;

  @Watch('options', { immediate: true, deep: true })
  onOptionsChanged(value: any, oldValue: any) {
    if(oldValue != undefined) {
      this.load();
    }
  }

  private get headers() {
    return [
      {
        text: 'State',
        value: 'State',
        sortable: true,
      },
      {
        text: 'Name',
        value: 'LocationName',
        sortable: true,
      },
      {
        text: 'Address',
        value: 'Address',
        sortable: true,
      },
      {
        text: 'Contact',
        value: 'Contact',
        sortable: true,
      },
      {
        text: 'Phone',
        value: 'Phone',
        sortable: true,
      },
      {
        text: 'Email',
        value: 'Email',
        sortable: true,
      },
      {
        text: 'Links',
        value: 'Jobs',
        sortable: false,
      },
    ];
  }

  private loading = false;
  private options = { sortBy:[], sortDesc:[], page: 1, itemsPerPage: 20 }; 
  private expanded = [];
  private loggedInUser: User | null = null;

  private items: PaginatedResults = {
    totalResults: 0,
    results: []
  };

  private async load() {
    this.loadError = "";
    this.loading = true;

    //Race condition with state ID change between parent and child components
    await this.$nextTick();

    const { sortBy, sortDesc, page, itemsPerPage } = this.options;
    this.loggedInUser = await UserAPI.getLoggedInUser();

    this.items = {
      totalResults: 0,
      results: []
    };

    try {
      this.items = await locationAPI.getLocations(page
        , itemsPerPage
        , sortBy.length === 1 ? sortBy[0] : null
        , sortDesc.length === 1 ? sortDesc[0] : null
        , this.stateID
        , this.location
        , this.contact
        , this.email);
    } catch (e) {
      this.loadError = (e as Error).message;
    } finally {
      this.loading = false;
    }
  }
}
