import { format, parse } from 'date-fns';
const DATE_FORMAT = "dd/MM/yyyy";
const TIME_FORMAT = "HH:mm";

export default class DateHelper {
  public static GetDateFromString(dateString: string): Date {
    const dateComponents: string[] = dateString.indexOf("-") > 0 ? dateString.split("-") : dateString.split("/");
    let dateStringFormatted = "";

    //dd/mm/yyyy
    if(dateComponents[2].length > 2) {
      dateStringFormatted = `${dateComponents[0]}/${dateComponents[1]}/${dateComponents[2]}`
    }
    //yyyy/mm/dd
    else {
      dateStringFormatted = `${dateComponents[2]}/${dateComponents[1]}/${dateComponents[0]}`
    }

    return parse(dateStringFormatted, DATE_FORMAT, new Date());
  }

  public static GetDateTimeFromString(dateTimeString: string): Date {
    const dateTimeComponents: string[] = dateTimeString.replace(" ", "/").replace(":", "/").split("/");
    let dateStringFormatted = "";

    //dd/mm/yyyy hh:mm
    if(dateTimeComponents[2].length > 2) {
      dateStringFormatted = `${dateTimeComponents[0]}/${dateTimeComponents[1]}/${dateTimeComponents[2]} ${dateTimeComponents[3]}:${dateTimeComponents[4]}`
    }
    //yyyy/mm/dd hh:mm
    else {
      dateStringFormatted = `${dateTimeComponents[2]}/${dateTimeComponents[1]}/${dateTimeComponents[0]} ${dateTimeComponents[3]}:${dateTimeComponents[4]}`
    }

    return parse(dateStringFormatted, DATE_FORMAT + " " + TIME_FORMAT, new Date());
  }

  public static GetDateAsDMYString(date: string | Date | null): string {  
    if (date == null)
      return "";
    else if (date instanceof Date)
      return format(date, DATE_FORMAT);
    else
      return format(this.GetDateFromString(date), DATE_FORMAT);
  }

  public static GetDateTimeAsDMYHMString(date: string | Date | null): string {
    if (date == null)
      return "";
    else if (date instanceof Date)
      return format(date, DATE_FORMAT + " " + TIME_FORMAT);
    else
      return format(this.GetDateTimeFromString(date), DATE_FORMAT + " " + TIME_FORMAT);
  }
}