import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { vxm } from '@/store/index';
import { Auth } from "aws-amplify";

import authService from "@/services/auth"

import LoginError from '@/components/authentication/LoginError.vue'
import LoggingIn from '@/components/authentication/LoggingIn.vue'
import LogOut from '@/components/authentication/LogOut.vue'
import LoggedOut from '@/components/authentication/LoggedOut.vue'
import NotAuthorized from '@/components/authentication/NotAuthorized.vue'

import HomeView from '../views/HomeView.vue'
import LocationView from '../views/LocationView.vue'
import JobsView from '../views/JobsView.vue'
import ReportsView from '../views/ReportsView.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: { name: 'Dashboard' },
    meta: {
      title: "Home",
      hide: true
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: HomeView,
    meta: {
      icon: 'mdi-home'
    }
  },
  {
    path: '/locations',
    name: 'Locations',
    component: LocationView,
    meta: {
      icon: 'mdi-map-marker'
    }
  },
  {
    path: '/jobs',
    name: 'Jobs',
    props: route => ({ 
      upcomingStatus: route.query.status, 
      locationID: route.query.locationId
    }),
    component: JobsView,
    meta: {
      icon: 'mdi-briefcase'
    }
  },
  {
    path: '/reports',
    name: 'Reports',
    props: route => ({ 
      jobReference: route.query.jobRef, 
      locationID: route.query.locationId
    }),
    component: ReportsView,
    meta: {
      icon: 'mdi-clipboard-text'
    }
  },
  {
    path: '/login', // This is intercepted, so nothing to display
    name: 'login',
    meta: {
      title: "Login",
      hide: true
    }
  },
  {
    path: '/loggingin', // This is intercepted, so nothing to display
    name: 'Logging In',
    component: LoggingIn,
    meta: {
      title: "Logging In...",
      hide: true
    }
  },
  {
    path: '/loginerror',
    name: 'Error',
    component: LoginError,
    meta: {
      title: "Login Error",
      hide: true
    }
  },
  {
    path: '/logout', // This is intercepted, so nothing to display
    name: 'Log Out',
    component: LogOut,
    meta: {
      title: "Client Portal",
      icon: 'mdi-logout'
    }
  },
  {
    path: '/loggedout',
    name: 'Logged Out',
    component: LoggedOut,
    meta: {
      title: "Client Portal",
      hide: true
    }
  },
  {
    path: '/notauthorized',
    name: 'Not Authorized',
    component: NotAuthorized,
    meta: {
      title: "Not Authorized",
      hide: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
const appTitle = process.env.VUE_APP_TITLE;

router.beforeEach(async (to, from, next) => {
  // this is some debug code you can use just to see if your user is logged in on every page load,
  // and print the details of the currently logged in user according to Amplify
  Auth.currentAuthenticatedUser()
  .then(user => {
    if (process.env.VUE_APP_ENVIRONMENT == 'dev-local') {
      // eslint-disable-next-line no-console
      console.log("You are currently authenticated. User: ", user);
    }

    //user is authenticated, ensure they get menu options
    const menuItems: RouteConfig[] = router.options.routes!.filter(r => !r.meta?.hide);
    vxm.GeneralStore.setMenuItems({ menuItems: menuItems });
    if (vxm.AuthStore.user == null) {
      // console.log('authsotre null')
      // console.log(vxm.AuthStore.user)
      authService.processLogin()
    }

    if (to.path === "/loggingin" || to.path === "/login") {
      // if we are logged in already (or a refresh succeeded)

      // fetch the user details from our database for use in determining permissions
      authService.processLogin()
      .then(() => {
        // successfully fetched user details through API => redirect to home
        next({
          path: "/"
        });
        return;
      })
      .catch(e => {
        // failed to fetch user details through API => redirect to login error to display to user

        // eslint-disable-next-line no-console
        console.log(e);

        next({
          path: "/loginerror"
        });

        return;
      });
    }

    // Check if we are going to the logout page (this would indicate a request to log out)
    if (to.path === "/logout") {
      // this will clear some of our internal storage and redirect the user to the Cognito log out page
      authService.logOut();
      next();
      return;
    }

    if (to.path === "/loggedout") {
      // not entirely sure why we are here if we are logged in, but if so: redirect to the home page
      next({
        path: "/"
      });

      return;
    }

    // - no authorisation applied to the page
    // => display the page
    next();
    return;
  })
  .catch(e => {
    // eslint-disable-next-line no-console
    console.log("You are not currently authenticated. Error: ", e);

    // user is not authenticated, they get no navigation options
    vxm.GeneralStore.setMenuItems({ menuItems: [] });

    authService.forgetUser();

    // check if we are going to the login page (this would indicate a request to log in)
    if (to.name === "login") {
      // redirect user to Cognito login
      authService.signIn();
      next();
      return;
    }
    if (to.name === "Logging In") {
      // we should only be on /loggingin when we have successfully come back from the Cognito login page
      // if we've done that and we're not logged in, it likely means there is a race condition at play
      // calling Amplify.Auth.currentAuthenticatedUser() too soon after a successful login will fail because... well I don't know why - Amazon is clearly a gigantic piece of shit
      // thus, we are going to just show the loggingin component, which will show a loading indicator and retry until it works or we get bored

      next();
      return;
    }

    if (to.path === "/loggedout") {
      // just display this page as normal if we are *actually* logged out
      next();
      return;
    }

    // if we are going to any other page, then instead redirect to the login page so that the user can login properly
    next({
      path: "/login"
    });
    return;
  });
})

router.afterEach((to, from) => {
  //route loaded
  vxm.GeneralStore.setLoadingRoute(false);

  Vue.nextTick(() => {
    document.title = appTitle!.concat(' | ', to.meta?.title ? to.meta!.title : to.name)
  })
})

export default router
