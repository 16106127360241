import * as BaseAPI from '@/api/baseAPI';
import PaginatedResults from '../../../shared/models/paginatedResults';
import Location from "../../../shared/models/location";
import { vxm } from '@/store';

export async function getLocations(page:number, itemsPerPage:number, sortBy:string | null, sortDesc:boolean | null, stateID:string | null, locationName:string | null, contact:string | null, email:string | null): Promise<PaginatedResults> {
  
  if(vxm.AuthStore.targetClient?.ClientID === undefined){
    throw new Error('No Client selected')
  }

  const result = await BaseAPI.simpleGet(`/locations?${[
    `page=${page}`,
    `itemsPerPage=${itemsPerPage}`,
    `sortBy=${sortBy}`,
    `sortDesc=${sortDesc}`,
    `clientID=${vxm.AuthStore.targetClient?.ClientID}`,
    `stateID=${stateID}`,
    `locationName=${locationName}`,
    `contact=${contact}`,
    `email=${email}`,
  ].join("&")}`);
  return result.data;
}

export async function getLocation(locationID: string): Promise<Location>{
  const result = await BaseAPI.simpleGet(`/locations/${locationID}`)

  if (result.data.length > 0)
    return result.data[0];
  else
    throw "Unable to find Location";
}