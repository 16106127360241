
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { Component, Prop, Vue } from 'vue-property-decorator';

import {Auth} from "aws-amplify";
import auth from '@/services/auth';

@Component({})
export default class LoggingIn extends Vue {
    private delaySeconds = 2;
    private maxDelayAttempts = 10;
    private attempts = 0;
    
    mounted(): void {
      // when this component is displayed, we are going to attempt to process a login that has already happened to AWS Cognito
      // if this fails, we are going to wait a little bit and try again - sometimes it takes a second or 2 for the logged in user information to be available
      // after trying enough times to be sure it's not going to work, we go to the login error page instead
      this.attemptLogin();
    }

    private attemptLogin(): void {
      // useful for debugging
      // Auth.currentSession()
      //   .then(data => console.log(data))
      //   .catch(err => console.log(err));

      Auth.currentAuthenticatedUser({ bypassCache: true})
        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
        .then((user: any) => {
          // if we're getting a response indicating that we are authenticated, then we can proceed with the login
          this.processLogin();
        })
        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
        .catch((e: any) => {
          // we just want to wait a few seconds and then try again
          this.delayAttemptLogin();
        });
    }

    private processLogin(): void {
      auth.processLogin()
        .then(() => {
          // successfully fetched user details through API => redirect to home
          this.$router.push({ name: "Dashboard" });
        })
        .catch(e => {
          // failed to fetch user details through API => redirect to login error to display to user

          // just blurt the error out to anyone that will listen
          // eslint-disable-next-line no-console
          console.log(e);

          // show an error page (error will not display on page, will need to check console and/or network tab to see what has happened)
          this.$router.push({ name: "loginerror" });
        });
    }

    private delayAttemptLogin(): void {
      if (this.attempts > this.maxDelayAttempts) {
        // this has gone on too long - stop trying and get out of here
        this.$router.push({ name: "loginerror" });
      }
      else {
        this.attempts++;

        // otherwise wait a bit and try again
        setTimeout(() => { this.attemptLogin(); }, this.delaySeconds * 1000);
      }
    }
}
