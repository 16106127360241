
import { Vue, Component, Watch } from 'vue-property-decorator';
import LocationList from '@/components/locations/LocationList.vue';
import State from '../../../shared/models/state'
import * as stateAPI from '@/api/stateAPI';
import { vxm } from '@/store';

@Component({
  components: {
    LocationList
  },
})
export default class LocationView extends Vue{
  private error = "";
  private selectedStateID: number | null = null;
  private searchedLocation: string | null = null;
  private searchedContact: string | null = null;
  private searchedEmail: string | null = null;
  private states: State[] = [];

  private async mounted() {
    try {
      this.states = await stateAPI.getStates();
    } 
    catch (ex: Error) {
      this.error = ex.mes;
    }
  }

  private async onSearchChanged() {
    this.$refs.locationListChild.load();
  }

  private clearFilters() {
    this.selectedStateID = null;
    this.searchedLocation = null;
    this.searchedContact = null;
    this.searchedEmail = null;
    this.$refs.locationListChild.load();
  }
  get selectedClientID() { return  vxm.AuthStore.targetClient?.ClientID; }
  
  @Watch('selectedClientID', { immediate: true, deep: true })
  async onClientIDChanged() {
    this.onSearchChanged();
  }
}
