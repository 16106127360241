
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as JobAPI from '@/api/jobAPI';
import PaginatedResults from '../../../../shared/models/paginatedResults';
import * as UserAPI from '@/api/userAPI';
import User from '../../../../shared/models/user';
import axios from 'axios';
import Report from '../../../../shared/models/report';
import reportsHelper from '@/helpers/reportsHelper';
import { vxm } from '@/store';

@Component({})
export default class JobList extends Vue {
  @Prop(String) public jobReference: string | null;
  @Prop(Number) public stateID: number | null;
  @Prop(String) public location: string | null;
  @Prop() public statusIDs: number[] | null;
  @Prop(String) public startDateFrom: string | null;
  @Prop(String) public startDateTo: string | null;
  @Prop(String) public finalisedDateFrom: string | null;
  @Prop(String) public finalisedDateTo: string | null;

  @Watch('options', { immediate: true, deep: true })
  onOptionsChanged(value: any, oldValue: any) {
    if(oldValue != undefined) {
      this.load();
    }
  }
  @Watch('selectedClient', { immediate: true, deep: true})
  onSelectedClientChanged(){
      this.load();
  }

  get selectedClient() { return  vxm.AuthStore.targetClient; }

  private get headers() {
    return [
      {
        text: 'Job Ref.',
        value: 'JobReference',
        sortable: true,
      },
      {
        text: 'State',
        value: 'State',
        sortable: true,
      },
      {
        text: 'Location',
        value: 'Location',
        sortable: true,
      },
      {
        text: 'Job Status',
        value: 'JobStatus',
        sortable: true,
      },
      {
        text: 'Start Date',
        value: 'StartDate',
        sortable: true,
      },
      {
        text: 'Finalised Date',
        value: 'FinalisedDate',
        sortable: true,
      },
    ];
  }

  private loading = false;
  private options = { sortBy:[], sortDesc:[], page: 1, itemsPerPage: 20 }; 
  private expanded = [];
  private loggedInUser: User | null = null;

  private downloadingReport = false;
  private errorDownloadReport = false;
  private reportError: string | null = null;

  private items: PaginatedResults = {
    totalResults: 0,
    results: []
  };

  private async load() {
    this.loadError = "";
    this.loading = true;

    //Race condition with state ID change between parent and child components
    await this.$nextTick();

    const { sortBy, sortDesc, page, itemsPerPage } = this.options;
    this.loggedInUser = await UserAPI.getLoggedInUser();

    this.items = {
      totalResults: 0,
      results: []
    };

    try {
      this.items = await JobAPI.getJobs(page
        , itemsPerPage
        , sortBy.length === 1 ? sortBy[0] : null
        , sortDesc.length === 1 ? sortDesc[0] : null
        , this.jobReference
        , this.stateID
        , this.location
        , this.statusIDs
        , this.startDateFrom
        , this.startDateTo
        , this.finalisedDateFrom
        , this.finalisedDateTo);
    } catch (e) {
      this.loadError = (e as Error).message;
    } finally {
      this.loading = false;
    }
  }

  private GetColourAsCSSAttribute(colourHex: string): string {
    return `background: ${colourHex}; color: white;`;
  }

  private async downloadReport(report: Report): void {
    this.downloadingReport = true;

    try {
      await reportsHelper.download(report);
    }
    catch(e) {
      this.downloadingReport = false;
      
      this.errorDownloadReport = true;
      this.reportError = (e as Error).message

      // eslint-disable-next-line no-console
      console.log('in component catch', e)
    }
    finally {
      this.downloadingReport = false;
    }
  }
}
