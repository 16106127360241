
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as ReportAPI from '@/api/reportAPI';
import Report from '../../../../shared/models/report';

@Component({})
export default class UpcomingJobsList extends Vue {
  @Prop(String) public locationID: string | null;
  @Prop(String) public clientID: string | null;

  private get headers() {
    return [
      {
        text: 'Job Ref.',
        value: 'JobReference',
        sortable: false,
      },
      {
        text: 'Location',
        value: 'Location',
        sortable: false,
      },
      {
        text: 'Report Type',
        value: 'ReportType',
        sortable: false,
      },
      {
        text: 'Publish Date',
        value: 'PublishDate',
        sortable: false,
      },
    ];
  }

  private loading = true;
  private recentJobReports: Report[] = [];

  @Watch('locationID', { immediate: true, deep: true })
    onItemChanged() {
      this.load();
    }

  @Watch('clientID', { immediate: true, deep: true })
  onClientIDLoaded() {
    if (this.clientID != "")
      this.load();
  }

  private async load() {
    this.loadError = "";

    try {
      this.recentJobReports = await ReportAPI.getTenRecentReports(this.clientID, this.locationID);
    } catch (ex: Error) {
      this.$emit("onLoad", ex);
    } finally {
      this.loading = false;
    }
  }
}
