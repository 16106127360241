
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { createModule, mutation, action } from "vuex-class-component";
import ExternalUserClient from "../../../shared/models/externalUserClient";
import User from "../../../shared/models/user"

const VuexModule = createModule({
  namespaced: "auth",
  strict: false
});

export class AuthStore extends VuexModule {
  /**
   * This is an object constructed from the cognito and the database that contains basic user details
   *  An example of what this looks like"
   *  {
        "Email": "rlloyd@comunet.com.au",
        "FirstName": "Rhiannon",
        "LastName": "Lloyd",
        "Phone": "+61400000000"
        "ClientID": GUIDvalue
        "Clients": [
          {
            "ClientID": GUIDValue
            "Name": "Bupa Victoria"
          }
        ]
      }
  */
  user: User | null = null;
  targetClient: ExternalUserClient | null = null;

  @mutation private defaultTargetClient() {
    const clients = this.user!.Clients!;
    // default the targetClient ID if it has no value
    if(this.targetClient === null || this.targetClient === undefined){
      if(clients.length > 0){
        this.targetClient = clients[0];
      }
      else {
        this.targetClient = null;
      }
    }
    //ensure any value that is persisted here is a client the current authorized user has access to
    const userHasClientAccess = this.user!.Clients?.some(c => c.ClientID === this.targetClient?.ClientID)
    if (!userHasClientAccess){
      if(clients.length > 0){
        this.targetClient = clients[0];
      }
      else {
        this.targetClient = null;
      }
    }
  }
  @mutation public setTargetClient(value: string | null) {
    const match = this.user!.Clients?.find(c => c.ClientID === value);
    if(match === undefined){
      this.targetClient = null;
      return;
    }
    this.targetClient = match!;
  }

  @mutation public setUser(value: User) {
    this.user = value
  }
  @mutation public forgetUser(){
    this.user = null;
  }

  @action async userLoggedIn(value: User) {
    this.setUser(value)
    this.defaultTargetClient()
  }
}